.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
.portfolio__items {
  border-radius: 2rem;
  padding: 1.2rem;
  margin: 0 auto;
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  transition: var(--transition);
}
.portfolio__items:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  /* cursor: default; */
}
.portfolio__items-image {
  border-radius: 1.5rem;
  overflow: hidden;
  object-fit: cover;
}
.portfolio__items h3 {
  /* color: var(--color-bg); */
  margin: 1.2rem 0 2rem;
}
.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

/*===== Media queries(medium devices)====*/
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/*===== Media queries(small devices)====*/
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
