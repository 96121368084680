header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}
.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*========CVC====*/
.cvc {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*==========HeaderSocials=====*/
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;

  /* justify-content:start; */
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/*============ME===========*/
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 35rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}
/*=======scroll down=====*/

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 2rem;
  /* font-size: 1rem; */
  /* position: fixed; */
}

/*============Media queries(medium device)==========*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/*============Media queries(small device)==========*/
@media screen and (max-width: 600px) {
  header {
    height: 90vh;
  }
  /* .me{
    width:70%
  } */
  .header__socials,
  .scroll__down {
    display: none;
  }
}
